<div data-scroll-container>
  <div class="content relative">
    <div
      class="sidebar-container top-0 left-0 fixed h-screen flex flex-wrap items-center justify-between flex-col"
    >
      <div class="logo">
        <a href="#">
          <img class="w-full" src="../../../assets/icon/logo.svg" alt="logo" />
        </a>
      </div>
      <div class="sidebar-menu fixed block">
        <ul>
          <li><a href="#about" data-scroll-to>About us</a></li>
          <li><a href="#creative" data-scroll-to>Services</a></li>
          <li><a href="#portfolio" data-scroll-to>Portfolio</a></li>
          <li><a href="#blog" data-scroll-to> Blog</a></li>
          <li><a href="#contact" data-scroll-to>Contact</a></li>
        </ul>
      </div>
    </div>
    <div class="progress-bar" [style.height.%]="scrollProgress"></div>

    <div class="home block lg:flex items-center" id="wrapper">
      <section class="home_intro" id="home" data-scroll-section>
        <div class="home_animation">
          <h1
            class="glitch relative uppercase lg:text-8xl md:text-6xl text-4xl"
            data-scroll
            data-scroll-repeat
            data-scroll-speed="1"
          >
            WE SHALL <span #typewriter class="line"></span>
          </h1>
          <ul class="page_link flex items-center" routerLink="services">
            <li><a (click)="selectTab('creative')">Creative</a></li>
            <li><a (click)="selectTab('web')">Web</a></li>
            <li><a (click)="selectTab('performance')">Performance</a></li>
            <li><a (click)="selectTab('content')">Content</a></li>
          </ul>
          <div class="scroll_button fixed flex items-center mt-[30px]" data-scroll>
            <a class="click_page flex items-center" data-target="about"
              ><i class="fa-solid fa-arrow-right"></i>
              <p class="pointer-events-none">Scroll</p>
            </a>
          </div>
        </div>
      </section>
      <app-what-we-do></app-what-we-do>
      <app-home-page-services></app-home-page-services>
      <app-portfolio></app-portfolio>
      <app-home-page-blog></app-home-page-blog>
      <app-contact></app-contact>
    </div>
  </div>
</div>
<!-- <app-background></app-background> -->
