<footer class="footer">
  <div class="mob_footer">
    <figure class="logo">
      <a routerLink="/">
        <img src="/assets/icon/main_logo.svg" alt="logo" />
      </a>
    </figure>
    <ul class="list">
      <li>
        <a href="https://twitter.com/nexotips" rel="noopener" target="_blank">
          <p>Tw</p>
        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/nexotips" rel="noopener" target="_blank">
          <p>Fb</p>
        </a>
      </li>
      <li>
        <a href="https://www.linkedin.com/company/nexotips" rel="noopener" target="_blank">
          <p>In</p>
        </a>
      </li>
      <li>
        <a href="https://blog.nexotips.com" rel="noopener" target="_blank">
          <p>Bl</p>
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/nexotips/" rel="noopener" target="_blank">
          <p>Ig</p>
        </a>
      </li>
    </ul>
    <div class="text">
      <p>© 2023 Audento Digital. All rights reserved.</p>
    </div>
  </div>
</footer>
