<div (mousemove)="onMouseMove($event)" class="main-wrapper">
  <svg
    class="cursor"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    [style.transform]="
      'translate(' + dotPosition.x + 'px, ' + dotPosition.y + 'px) scale(' + dotScale + ')'
    "
    [class.scaled]="isScaling"
  >
    <circle class="cursor__inner" cx="10" cy="10" r="5"></circle>
  </svg>
  <app-header></app-header>
  <app-sidebar></app-sidebar>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
</div>
