import { Routes } from '@angular/router';
import { HomeComponent } from './components/home-page/home/home.component';
import { AboutPageComponent } from './components/about-page/about-page.component';
import { ServicesComponent } from './components/service-page/services/services.component';
import { CommonDetailsPageComponent } from './components/service-page/common-details-page/common-details-page.component';
import { ContactPageComponent } from './components/contact-page/contact-page.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'about',
    component: AboutPageComponent,
  },
  {
    path: 'services',
    component: ServicesComponent,
  },
  {
    path: 'service-details',
    component: CommonDetailsPageComponent,
  },
  {
    path: 'contact',
    component: ContactPageComponent,
  },
];
