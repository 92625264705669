import { Component } from '@angular/core';
import { RouteService } from '../../../core/services/route.service';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-home-page-services',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './home-page-services.component.html',
  styleUrl: './home-page-services.component.scss',
})
export class HomePageServicesComponent {
  constructor(private routeService: RouteService) {}

  selectTab(tab: string) {
    this.routeService.setSelectedTab(tab);
  }
}
