import { Component } from '@angular/core';
import { RippleService } from '../../core/services/ripple.service';
import { CommonModule } from '@angular/common';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { RouteService } from '../../core/services/route.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  isShown: boolean = true;
  isActive: boolean = false;

  constructor(
    private rippleService: RippleService,
    private router: Router,
    private routeService: RouteService
  ) {
    router.events.subscribe((value) => {
      if (value instanceof NavigationEnd) {
        if (value.url == '/') {
          this.isActive = false;
        } else if (value.url == '/about') {
          this.isActive = false;
        } else {
          this.isActive = true;
        }
      }
    });
  }

  onMouseOver(event: MouseEvent) {
    const element = event.currentTarget as HTMLElement;
    this.rippleService.createRipple(event, element);
  }

  closeMenu() {
    if (!this.isShown) {
      this.isShown = !this.isShown;
    } else {
      this.isShown = !this.isShown;
    }
  }

  selectTab(tab: string) {
    this.routeService.setSelectedTab(tab);
  }
}
