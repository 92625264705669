<section class="service_sec" id="contact" data-scroll-section>
  <div
    class="lg:flex flex-nowrap block items-center lg:pr-[161px] p-0"
    [ngClass]="{ show: showClass }"
  >
    <div
      class="portfolio-img-wrap img_wraper is-inview"
      data-scroll
      data-scroll-repeat
      data-scroll-sticky
      data-scroll-target="#contact"
    >
      @if(showClass){
      <a routerLink="/" class="to_back"><i class="fa-solid fa-arrow-left"></i> Back To Home</a>}
      <span data-scroll data-scroll-speed="1.5" class="is-inview">
        <figure
          class="section_img section-img lazyloaded"
          style="background-image: url('assets/image/contact.png')"
        ></figure>
      </span>
    </div>

    <div class="row" data-scroll data-scroll-speed="1">
      <div class="content_div is-inview portfolio-content">
        <div class="sec_head">
          <span class="for_mob">Contact Us</span>
          <h2>
            Interested?<br />
            Let’s talk!
          </h2>
        </div>
        <p class="lg:flex block flex-wrap">
          Just fill this simple form in and we will contact you promptly to discuss your project.
          Hate forms? Drop us a line at
          <a class="contact_email" href="mailto:connect@ audentodigital.com"
            >connect&#64; audentodigital.com</a
          >.
        </p>

        <div class="leading-4" [ngClass]="{ hide: showClass }">
          <a class="common-arrow" routerLink="contact">
            Read More
            <span><i class="fa-solid fa-arrow-right"></i></span>
          </a>
        </div>
        <div class="img_mob_only" [ngClass]="{ imagShow: showClass }">
          <figure
            class="section_img lazyloaded"
            style="background-image: url('assets/image/contact.png')"
          ></figure>
        </div>
      </div>

      <div class="col_form">
        <div class="form-wrapper">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a class="nav-link disrtoy_body" (click)="talkBtn()" [ngClass]="{ active: is1Active }"
                >Reach out to us</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link disrtoy_body"
                (click)="contactBtn()"
                [ngClass]="{ active: is2Active }"
                >Say hi</a
              >
            </li>
          </ul>
          <div class="tab-content">
            <!-- say hi -->
            <!-- <div
              class="tab-pane fade"
              (click)="contactBtn()"
              [ngClass]="{ active: is2Active }"
            >
              <form class="form" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                <div class="form_outer">
                  <div class="form-group">
                    <input
                      class="form-input"
                      type="text"
                      placeholder="Name*"
                      name="Name"
                      formControlName="name"
                      required
                    />
                    <div
                      class="form-error"
                      *ngIf="
                        contactForm.controls['name'].invalid && contactForm.controls['name'].touched
                      "
                    >
                      <div
                        class="error-message"
                        *ngIf="contactForm.controls['name'].errors?.['required']"
                      >
                        Name is required
                      </div>
                      <div
                        class="error-message"
                        *ngIf="contactForm.controls['name'].errors?.['minlength']"
                      >
                        Name must be at least 3 characters
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <input
                      class="form-input"
                      type="email"
                      placeholder="Email Id*"
                      name="Email_id"
                      formControlName="email"
                      required
                    />
                    <div
                      class="form-error"
                      *ngIf="
                        contactForm.controls['email'].invalid &&
                        contactForm.controls['email'].touched
                      "
                    >
                      <div
                        class="error-message"
                        *ngIf="contactForm.controls['email'].errors?.['required']"
                      >
                        Email is required
                      </div>
                      <div
                        class="error-message"
                        *ngIf="contactForm.controls['email'].errors?.['email']"
                      >
                        Invalid email address
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <input
                      class="form-input"
                      type="number"
                      placeholder="Mobile*"
                      name="Mobile"
                      formControlName="phone"
                      required
                    />
                    <div
                      class="form-error"
                      *ngIf="
                        contactForm.controls['phone'].invalid &&
                        contactForm.controls['phone'].touched
                      "
                    >
                      <div
                        class="error-message"
                        *ngIf="contactForm.controls['phone'].errors?.['required']"
                      >
                        Mobile number is required
                      </div>
                      <div
                        class="error-message"
                        *ngIf="contactForm.controls['phone'].errors?.['pattern']"
                      >
                        Invalid Mobile number
                      </div>
                      <div
                        class="error-message"
                        *ngIf="contactForm.controls['phone'].errors?.['pattern'] || contactForm.controls['phone'].errors?.['minlength']"
                      >
                        Mobile number must be a 10-digit number
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <textarea
                      class="form-input"
                      type="textarea"
                      placeholder="Message*"
                      name="Message"
                      formControlName="desc"
                      required
                    ></textarea>
                    <div
                      class="form-error"
                      *ngIf="
                        contactForm.controls['desc'].invalid && contactForm.controls['desc'].touched
                      "
                    >
                      <div
                        class="error-message"
                        *ngIf="contactForm.controls['desc'].errors?.['required']"
                      >
                        Message is required
                      </div>
                      <div
                        class="error-message"
                        *ngIf="contactForm.controls['desc'].errors?.['minlength']"
                      >
                        Message must be at least 3 characters
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <button id="work-btn" type="submit" class="common-arrow">
                      Submit<span><i class="fa-solid fa-arrow-right"></i></span>
                    </button>
                  </div>
                </div>
              </form>
            </div> -->
            <!-- work with us -->
            <div class="tab-pane fade" (click)="talkBtn()" [ngClass]="{ active: is1Active }">
              <form class="form" [formGroup]="talkForm" (ngSubmit)="onSubmit()">
                <div class="form_outer">
                  <div class="form-group">
                    <input
                      class="form-input"
                      type="text"
                      placeholder="Name*"
                      name="Name"
                      formControlName="name"
                      required
                    />
                    <div
                      class="form-error"
                      *ngIf="talkForm.controls['name'].invalid && talkForm.controls['name'].touched"
                    >
                      <div
                        class="error-message"
                        *ngIf="talkForm.controls['name'].errors?.['required']"
                      >
                        Name is required
                      </div>
                      <div
                        class="error-message"
                        *ngIf="talkForm.controls['name'].errors?.['minlength']"
                      >
                        Name must be at least 3 characters
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <input
                      class="form-input"
                      type="email"
                      placeholder="Email Id*"
                      name="Email_id"
                      formControlName="email"
                      required
                    />
                    <div
                      class="form-error"
                      *ngIf="
                        talkForm.controls['email'].invalid && talkForm.controls['email'].touched
                      "
                    >
                      <div
                        class="error-message"
                        *ngIf="talkForm.controls['email'].errors?.['required']"
                      >
                        Email is required
                      </div>
                      <div
                        class="error-message"
                        *ngIf="talkForm.controls['email'].errors?.['email']"
                      >
                        Invalid email address
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <input
                      class="form-input"
                      type="text"
                      placeholder="Mobile*"
                      name="Mobile"
                      formControlName="phone"
                      required
                    />
                    <div
                      class="form-error"
                      *ngIf="
                        talkForm.controls['phone'].invalid && talkForm.controls['phone'].touched
                      "
                    >
                      <div
                        class="error-message"
                        *ngIf="talkForm.controls['phone'].errors?.['required']"
                      >
                        Mobile number is required
                      </div>
                      <div
                        class="error-message"
                        *ngIf="talkForm.controls['phone'].errors?.['pattern']"
                      >
                        Invalid Mobile number
                      </div>
                      <div
                        class="error-message"
                        *ngIf="talkForm.controls['phone'].errors?.['pattern'] || talkForm.controls['phone'].errors?.['minlength']"
                      >
                        Mobile number must be a 10-digit number
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <textarea
                      class="form-input"
                      type="textarea"
                      placeholder="Message*"
                      name="Message"
                      formControlName="desc"
                      required
                    ></textarea>
                    <div
                      class="form-error"
                      *ngIf="talkForm.controls['desc'].invalid && talkForm.controls['desc'].touched"
                    >
                      <div
                        class="error-message"
                        *ngIf="talkForm.controls['desc'].errors?.['required']"
                      >
                        Message is required
                      </div>
                      <div
                        class="error-message"
                        *ngIf="talkForm.controls['name'].errors?.['minlength']"
                      >
                        Message must be at least 3 characters
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form_need_btns">
                  <div class="form-group">
                    <h3>I need *</h3>
                    <div class="buttons_outer">
                      <ul class="radio_btns">
                        <li *ngFor="let service of services" class="service-item">
                          <button
                            type="button"
                            class="select_tech d-flex align-items-center justify-content-center"
                            [ngClass]="{ active: service.selected }"
                            (click)="selectService(service)"
                          >
                            {{ service.name }}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="form-group">
                    <button id="work-btn" type="submit" class="common-arrow">
                      Submit<span><i class="fa-solid fa-arrow-right"></i></span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contact_address">
      <div class="outer_div_add" data-scroll data-scroll-speed="1.5">
        <div class="div_item_address">
          <h4>Call</h4>
          <a href="tel:+919446671353">+91 9446671353</a>
        </div>
        <div class="div_item_address">
          <h4>Write</h4>
          <a href="mailto:connect@ audentodigital.com">connect &#64; audentodigital.com</a>
          <a class="whatsapp" href="#" target="_blank">Whatsapp</a>
        </div>
        <div class="div_item_address">
          <h4>Visit us at</h4>
          <p>Level 11, Nippon Q1, NH Bypass, Vennala, Ernakulam, Kerala - 682028</p>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="toast">
  <span class="toast-message"></span>
  <i class="bi bi-x-lg toast-close"></i>
</div>
