<section class="pl-[242px] block" id="about" data-scroll-section>
  <div class="what_weDo relative max-w-full" data-scroll data-scroll-speed="3">
    <div class="sec_head">
      <span class="for_mob">About Us</span>
      <h2>At Heart, We Are Bridge Builders</h2>
    </div>
    <p>
      We are just a young(at heart) Digital Advertising Agency that loves to Break, Build &amp;
      Bridge.
    </p>
    <p class="mt-[15px]">We don't simply sell a brand to its audience, we sell a connection.</p>
    <div class="what_weDo_btn leading-4">
      <a routerLink="/about" class="common-arrow">
        Know more
        <span><i class="fa-solid fa-arrow-right"></i></span>
      </a>
    </div>
  </div>
</section>
