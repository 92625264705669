import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent {
  showSidebar: boolean = false;

  constructor(private router: Router) {
    router.events.subscribe((value) => {
      if (value instanceof NavigationEnd) {
        if (value.url == '/') {
          this.showSidebar = false;
        } else {
          this.showSidebar = true;
        }
      }
    });
  }
}
