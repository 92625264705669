<div data-scroll-container class="service_page inner_Page">
  <div class="content relative">
    <div class="home flex" id="wrapper">
      <section class="service_sec service_detail" id="services">
        <div class="img_wraper">
          <a routerLink="/" routerLinkActive="router-link-active" class="to_back"
            ><i class="fa-solid fa-arrow-left"></i> Back To Home</a
          >
          <span data-scroll data-scroll-speed="1.5">
            <figure
              class="section_img"
              style="background-image: url('assets/services/coding.jpg')"
            ></figure>
          </span>
        </div>
        <div class="service_content" id="service_content">
          <div class="heading" data-scroll data-scroll-repeat data-scroll-target="#service_content">
            <div>
              <span class="mob_back"
                ><a routerLink="/" routerLinkActive="router-link-active" class="to_back"
                  ><i class="fa-solid fa-arrow-left"></i> Back</a
                ></span
              >
              <label>1.1</label>
              <h1>Animation</h1>
              <p>
                Animation is the art of making pictures move. We are in the art of making the
                pictures move you.
              </p>
              <div class="for_mob_only">
                <figure
                  class="section_img lazyloaded"
                  style="background-image: url('assets/services/coding.jpg')"
                ></figure>
              </div>
            </div>
          </div>
          <div class="contents_div">
            <p>
              Once we understand your brand and its core message, we can develop the brand's digital
              assets that can be used to create your brand pictures that come alive. As with any
              good animation, our team of creators work together starting with coming out with the
              concept and then taking a strategic approach to achieve its goal within the brand
              guidelines and time frame.
            </p>
            <p>
              Our capable team of creators can help your brand with the latest design and animation
              trends in the digital market using mixed media formats. Our services include Ad Films,
              Film Editing, Film with CGI, Comping, Compositing, VFX, VFX-Supervision, 3D Modelling,
              Motion design, and all Digital Media Combinations.
            </p>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
