<section class="service_sec blog_sec blog pr-[420px]" id="blog" data-scroll-section>
  <div
    class="img_wraper"
    data-scroll
    data-scroll-repeat
    data-scroll-sticky
    data-scroll-target="#blog"
  >
    <span data-scroll data-scroll-speed="1.5">
      <figure
        class="section_img lazyloaded"
        style="background-image: url('assets/about/blog_sec.jpg')"
      ></figure>
    </span>
  </div>
  <div class="row">
    <div class="content_div" data-scroll data-scroll-speed="1">
      <div class="sec_head">
        <span class="for_mob">blog</span>
        <h2>Our Stories</h2>
      </div>
      <p>
        Everyone has a story to tell. Here is ours.<br />
        Learn more about Digital Advertising. Learn more about Audento Digital.
      </p>
    </div>
    <div class="blog_item">
      <div class="item_div mb-8 pb-8">
        <div class="blog_content">
          <a
            ><label class="update text-xs font-medium leading-6 text-[#dddddd]">Latest</label>
            <div class="figure scene">
              <figure
                class="lazyloaded"
                data-depth="2"
                style="background-image: url('assets/about/gif_video.jpg')"
              ></figure>
            </div>
            <h3 class="text-2xl font-bold text-white leading-6 overflow-hidden">
              Images vs Video vs GIFs- Which Should I choose?
            </h3>
            <i class="fa-solid fa-arrow-right"></i>
          </a>
        </div>
      </div>
      <div class="item_div mb-8 pb-8">
        <div class="blog_content">
          <a
            ><label class="update text-xs font-medium leading-6 text-[#dddddd]">Latest</label>
            <div class="figure scene">
              <figure
                class="lazyloaded"
                data-depth="2"
                style="background-image: url('assets/about/socialmedia.jpg')"
              ></figure>
            </div>
            <h3 class="text-2xl font-bold text-white leading-6 overflow-hidden">
              Upgrade your brand with these 5 social media tools
            </h3>
            <i class="fa-solid fa-arrow-right"></i>
          </a>
        </div>
      </div>
      <div class="item_div mb-8 pb-8">
        <div class="blog_content">
          <a
            ><label class="update text-xs font-medium leading-6 text-[#dddddd]">Latest</label>
            <div class="figure scene">
              <figure
                class="lazyloaded"
                data-depth="2"
                style="background-image: url('assets/about/marketing_blog.jpg')"
              ></figure>
            </div>
            <h3 class="text-2xl font-bold text-white leading-6 overflow-hidden">
              What the future looks like for content marketing?
            </h3>
            <i class="fa-solid fa-arrow-right"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
