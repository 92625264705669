import { Component, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { ContactComponent } from '../home-page/contact/contact.component';
import { isPlatformBrowser } from '@angular/common';
import LocomotiveScroll from 'locomotive-scroll';

@Component({
  selector: 'app-contact-page',
  standalone: true,
  imports: [ContactComponent],
  templateUrl: './contact-page.component.html',
  styleUrl: './contact-page.component.scss',
})
export class ContactPageComponent {
  isBrowser: boolean;
  private scroll: LocomotiveScroll | undefined;
  scrollProgress = 0;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngAfterViewInit() {
    this.initializeScroll();
  }

  private initializeScroll() {
    if (this.isBrowser) {
      const element = document.querySelector('[data-scroll-container]') as HTMLElement;
      const direction = window.innerWidth <= 1024 ? 'vertical' : 'horizontal';

      this.scroll = new LocomotiveScroll({
        el: element,
        smooth: true,
        direction: direction,
        smartphone: { smooth: true },
      });

      this.scroll.update();
      this.scroll.on('scroll', (args: any) => {
        const { scroll, limit } = args;
        this.scrollProgress = (scroll.x / limit.x) * 100;
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    if (this.isBrowser) {
      if (this.scroll) {
        this.scroll.destroy();
      }
      this.initializeScroll();
    }
  }
}
