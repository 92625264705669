import { Component, ElementRef, HostListener, Inject, Renderer2, ViewChild } from '@angular/core';
import LocomotiveScroll from 'locomotive-scroll';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { TypewriterService } from '../../../core/services/typewriter.service';
import { HomePageServicesComponent } from '../home-page-services/home-page-services.component';
import { WhatWeDoComponent } from '../what-we-do/what-we-do.component';
import { PortfolioComponent } from '../portfolio/portfolio.component';
import { HomePageBlogComponent } from '../home-page-blog/home-page-blog.component';
import { ContactComponent } from '../contact/contact.component';
import { RouteService } from '../../../core/services/route.service';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    HomePageServicesComponent,
    WhatWeDoComponent,
    PortfolioComponent,
    HomePageBlogComponent,
    ContactComponent,
    RouterModule,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent {
  @ViewChild('typewriter', { static: true }) typewriterElement!: ElementRef;
  isBrowser: any;
  private scroll: LocomotiveScroll | undefined;
  scrollProgress = 0;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private typewriterService: TypewriterService,
    private renderer: Renderer2,
    private routeService: RouteService
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngAfterViewInit() {
    const dataText = ['dare.', 'venture.', 'risk.'];
    this.typewriterService.startTextAnimation(0, dataText, this.typewriterElement.nativeElement);
    this.initializeScroll();
  }

  private initializeScroll() {
    if (this.isBrowser) {
      const element = document.querySelector('[data-scroll-container]') as HTMLElement;
      const direction = window.innerWidth <= 1024 ? 'vertical' : 'horizontal';

      this.scroll = new LocomotiveScroll({
        el: element,
        smooth: true,
        direction: direction,
        smartphone: { smooth: true },
      });

      this.scroll.update();
      this.scroll.on('scroll', (args: any) => {
        const { scroll, limit } = args;
        this.scrollProgress = (scroll.x / limit.x) * 100;
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    if (this.isBrowser) {
      if (this.scroll) {
        this.scroll.destroy();
      }
      this.initializeScroll();
    }
  }

  selectTab(tab: string) {
    this.routeService.setSelectedTab(tab);
  }
}
