<div data-scroll-container class="service_page inner_Page">
  <div class="content relative">
    <div class="progress-bar" [style.height.%]="scrollProgress"></div>
    <div class="home flex items-center" id="wrapper">
      <section class="service_sec" id="services" data-scroll-section>
        <div class="img_wraper">
          <a routerLink="/" routerLinkActive="router-link-active" class="to_back"
            ><i class="fa-solid fa-arrow-left"></i> Back To Home</a
          >
          <span data-scroll data-scroll-speed="1.5">
            <figure
              class="section_img"
              style="background-image: url('assets/services/blog_bnr.jpg')"
            ></figure>
          </span>
        </div>
        <div id="service_sec_outer">
          <div
            class="service_head_div"
            data-scroll
            data-scroll-repeat
            data-scroll-sticky
            data-scroll-target="#service_sec_outer"
          >
            <div class="heading">
              <span class="mob_back"
                ><a routerLink="/" class="to_back"
                  ><i class="fa-solid fa-arrow-left"></i> Back</a
                ></span
              >
              <span class="for_mob">Services</span>
              <h1>Services</h1>
              <ul class="nav nav-pills" id="pills-tab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link disrtoy_body active"
                    [ngClass]="{ active: selectedTab === 'creative' }"
                    (click)="selectTab('creative')"
                    role="tab"
                    aria-selected="true"
                    ><span>01.</span><label>Creative</label></a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link disrtoy_body"
                    [ngClass]="{ active: selectedTab === 'web' }"
                    (click)="selectTab('web')"
                    role="tab"
                    aria-selected="false"
                    ><span>02.</span><label>Web</label></a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link disrtoy_body"
                    [ngClass]="{ active: selectedTab === 'performance' }"
                    (click)="selectTab('performance')"
                    role="tab"
                    aria-selected="false"
                    ><span>03.</span><label>Performance</label></a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link disrtoy_body"
                    [ngClass]="{ active: selectedTab === 'content' }"
                    (click)="selectTab('content')"
                    role="tab"
                    aria-selected="false"
                    ><span>04.</span><label>Content</label></a
                  >
                </li>
              </ul>
            </div>
            <p>Deep beneath, we are all storytellers waiting to tell your story to the world</p>
          </div>
          <div class="for_mob_only">
            <figure
              class="section_img lazyloaded"
              style="background-image: url('assets/services/blog_bnr.jpg')"
            ></figure>
            <ul class="nav nav-pills" id="pills-tab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link disrtoy_body active"
                  [ngClass]="{ active: selectedTab === 'creative' }"
                  (click)="selectTab('creative')"
                  role="tab"
                  aria-selected="true"
                  ><label>Creative</label></a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link disrtoy_body"
                  [ngClass]="{ active: selectedTab === 'web' }"
                  (click)="selectTab('web')"
                  role="tab"
                  aria-selected="false"
                  ><label>Web</label></a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link disrtoy_body"
                  [ngClass]="{ active: selectedTab === 'performance' }"
                  (click)="selectTab('performance')"
                  role="tab"
                  aria-selected="false"
                  ><label>Performance</label></a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link disrtoy_body"
                  [ngClass]="{ active: selectedTab === 'content' }"
                  (click)="selectTab('content')"
                  role="tab"
                  aria-selected="false"
                  ><label>Content</label></a
                >
              </li>
            </ul>
          </div>
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade"
              [ngClass]="{ 'active show': selectedTab === 'creative' }"
              id="pills-creative"
            >
              <div class="row">
                <div class="content_div">
                  <div class="sec_head">
                    <label>01.</label>
                    <h2>Creative</h2>
                  </div>
                  <p>
                    A picture is worth a thousand words. And the pictures our <b>Creators</b> can
                    capture can portray the totality of your brand’s <b>Image</b>.
                  </p>
                </div>
                <div class="col_item">
                  <div class="item_div">
                    <label>1.1</label>
                    <h4>Animation</h4>
                    <div class="img_content">
                      <figure style="background-image: url('assets/about/running.jpg')">
                        <a routerLink="/service-details"><i class="fa-solid fa-arrow-right"></i></a>
                      </figure>
                      <p>
                        Make your ideas come alive with our team of <b>Animators</b> who specialise
                        in making your brand’s ideas move.
                      </p>
                    </div>
                  </div>
                  <div class="item_div">
                    <label>1.2</label>
                    <h4>Design</h4>
                    <div class="img_content">
                      <figure style="background-image: url('assets/services/Creative-04.jpg')">
                        <a routerLink="/service-details"><i class="fa-solid fa-arrow-right"></i></a>
                      </figure>
                      <p>
                        What you see is what you get and what you get from our <b>Designers</b> are
                        designs that sell, innovate and speak your brand’s language.
                      </p>
                    </div>
                  </div>
                  <div class="item_div">
                    <label>1.3</label>
                    <h4>Editing</h4>
                    <div class="img_content">
                      <figure style="background-image: url('assets/services/Creative-02.jpg')">
                        <a routerLink="/service-details"><i class="fa-solid fa-arrow-right"></i></a>
                      </figure>
                      <p>
                        Our team of professional <b>Creators</b> can help your brand edit out what
                        isn't needed for your brand and help your brand overcome success over and
                        over again.
                      </p>
                    </div>
                  </div>
                  <div class="item_div">
                    <label>1.4</label>
                    <h4>Illustration</h4>
                    <div class="img_content">
                      <figure style="background-image: url('assets/services/Creative-03.jpg')">
                        <a routerLink="/service-details"><i class="fa-solid fa-arrow-right"></i></a>
                      </figure>
                      <p>
                        Our Creators also specialise in creating from scratch impactful
                        <b>Illustrations</b>, Logos and more that tells your brand's
                        <b>stories</b> more vibrantly.
                      </p>
                    </div>
                  </div>
                  <div class="item_div">
                    <label>1.5</label>
                    <h4>Photography</h4>
                    <div class="img_content">
                      <figure style="background-image: url('assets/services/Photo.jpg')">
                        <a routerLink="/service-details"><i class="fa-solid fa-arrow-right"></i></a>
                      </figure>
                      <p>
                        Capture your product in the best setting. Our world-class photography team
                        constantly adapts to new challenges and creates new standards in their
                        field.
                      </p>
                    </div>
                  </div>
                  <div class="item_div">
                    <label>1.6</label>
                    <h4>Videography</h4>
                    <div class="img_content">
                      <figure style="background-image: url('assets/services/Video.jpg')">
                        <a routerLink="/service-details"><i class="fa-solid fa-arrow-right"></i></a>
                      </figure>
                      <p>
                        From clear explainers to heart-wrenching ads, our team of videographers
                        perfectly encapsulate your brand's message. Scripting to Final Render, we do
                        it all.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              [ngClass]="{
                'active show': selectedTab === 'web'
              }"
            >
              <div class="row">
                <div class="content_div">
                  <div class="sec_head">
                    <label>02.</label>
                    <h2>Website</h2>
                  </div>
                  <p>
                    Personalise your Digital Brand Home. Our website developers along with our
                    designers and writers, work together in architecting the perfect digital
                    <b>home</b> where your brand can be free to express itself. This is where anyone
                    and everyone can visit to get acquainted with your brand.
                  </p>
                </div>
                <div class="col_item">
                  <div class="item_div">
                    <label>2.1</label>
                    <h4>UI / UX</h4>
                    <div class="img_content">
                      <figure style="background-image: url('assets/services/ui_ux.jpg')">
                        <a routerLink="/service-details"><i class="fa-solid fa-arrow-right"></i></a>
                      </figure>
                      <p>
                        We make sure the travel through your digital website is nothing short of an
                        exciting <b>experience</b>. We also make sure it’s as <b>friendly</b> and
                        <b>simple</b> as it can get for all to comfortably travel through it.
                      </p>
                    </div>
                  </div>
                  <div class="item_div">
                    <label>2.2</label>
                    <h4>Development</h4>
                    <div class="img_content">
                      <figure style="background-image: url('assets/about/development.jpg')">
                        <a routerLink="/service-details"><i class="fa-solid fa-arrow-right"></i></a>
                      </figure>
                      <p>
                        Whether it be a simple landing page or complex web applications, electronic
                        businesses, and social network services, our team of web developers can
                        <b>develop</b> them all.
                      </p>
                    </div>
                  </div>
                  <div class="item_div">
                    <label>2.3</label>
                    <h4>E-Commerce</h4>
                    <div class="img_content">
                      <figure style="background-image: url('assets/services/e_commerce.jpg')">
                        <a routerLink="/service-details"><i class="fa-solid fa-arrow-right"></i></a>
                      </figure>
                      <p>
                        For us, setting up a brand’s digital store means setting up a feeling of
                        actually visiting your brand and connecting with it in every corner leading
                        to timeless recurring sales.
                      </p>
                    </div>
                  </div>
                  <div class="item_div">
                    <label>2.4</label>
                    <h4>WordPress</h4>
                    <div class="img_content">
                      <figure style="background-image: url('assets/services/wordpress.jpg')">
                        <a routerLink="/service-details"><i class="fa-solid fa-arrow-right"></i></a>
                      </figure>
                      <p>
                        We specialize in the infrastructure and <b>ecosystem</b> of the WordPress
                        platform focusing on improving the software itself or creating new products,
                        particularly plugins and themes that suit your brand.
                      </p>
                    </div>
                  </div>
                  <div class="item_div">
                    <label>2.5</label>
                    <h4>PHP</h4>
                    <div class="img_content">
                      <figure style="background-image: url('assets/services/php.jpg')">
                        <a routerLink="/service-details"><i class="fa-solid fa-arrow-right"></i></a>
                      </figure>
                      <p>
                        We provide a wide range of tailor-made PHP <b>solutions</b> that give your
                        brand competitive edge in the digital space.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              [ngClass]="{
                'active show': selectedTab === 'performance'
              }"
            >
              <div class="row">
                <div class="content_div">
                  <div class="sec_head">
                    <label>03.</label>
                    <h2>Performance</h2>
                  </div>
                  <p>
                    <b>Reach</b> where no man or company has reached before with specialised
                    strategies and optimised analytics to target and reach your treasured
                    <b>audience</b> wherever they are.
                  </p>
                </div>
                <div class="col_item">
                  <div class="item_div">
                    <label>3.1</label>
                    <h4>Social Media Ads</h4>
                    <div class="img_content">
                      <figure style="background-image: url('assets/services/social_media_ads.jpg')">
                        <a routerLink="/service-details"><i class="fa-solid fa-arrow-right"></i></a>
                      </figure>
                      <p>
                        We use social media platforms to <b>engage</b> your brand with your audience
                        and <b>build</b> better connections along the way.
                      </p>
                    </div>
                  </div>
                  <div class="item_div">
                    <label>3.2</label>
                    <h4>Campaign Management</h4>
                    <div class="img_content">
                      <figure style="background-image: url('assets/about/performance.jpg')">
                        <a routerLink="/service-details"><i class="fa-solid fa-arrow-right"></i></a>
                      </figure>
                      <p>
                        We can help your brand with the best <b>strategy</b> to launch and perform
                        in the digital space.
                      </p>
                    </div>
                  </div>
                  <div class="item_div">
                    <label>3.3</label>
                    <h4>Display Ads</h4>
                    <div class="img_content">
                      <figure style="background-image: url('assets/services/display_ads.jpg')">
                        <a routerLink="/service-details"><i class="fa-solid fa-arrow-right"></i></a>
                      </figure>
                      <p>
                        We can help you display your brand’s messages where your brand’s audience
                        really are.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              [ngClass]="{
                'active show': selectedTab === 'content'
              }"
            >
              <div class="row">
                <div class="content_div">
                  <div class="sec_head">
                    <label>04.</label>
                    <h2>Content</h2>
                  </div>
                  <p>
                    Words have the power of their own and when placed carefully together, they come
                    out with <b>meaning</b>. Our writers craft words carefully together to give your
                    brand the perfect <b>identity</b> and message it needs to deliver to your
                    <b>audience</b>.
                  </p>
                </div>
                <div class="col_item">
                  <div class="item_div">
                    <label>4.1</label>
                    <h4>Scriptwriting</h4>
                    <div class="img_content">
                      <figure style="background-image: url('assets/services/script_writing.jpg')">
                        <a routerLink="/service-details"><i class="fa-solid fa-arrow-right"></i></a>
                      </figure>
                      <p>
                        We don't just write ads. We write <b>stories</b> that capture the
                        <b>hearts</b> of our audience.
                      </p>
                    </div>
                  </div>
                  <div class="item_div">
                    <label>4.2</label>
                    <h4>SEO</h4>
                    <div class="img_content">
                      <figure style="background-image: url('assets/services/seo.jpg')">
                        <a routerLink="/service-details"><i class="fa-solid fa-arrow-right"></i></a>
                      </figure>
                      <p>
                        We find out what works and what doesn't for your brand and
                        <b>optimise</b> it so that your brand always stays at the top.
                      </p>
                    </div>
                  </div>
                  <div class="item_div">
                    <label>4.3</label>
                    <h4>Blogs</h4>
                    <div class="img_content">
                      <figure style="background-image: url('assets/services/blogs_img.jpg')">
                        <a routerLink="/service-details"><i class="fa-solid fa-arrow-right"></i></a>
                      </figure>
                      <p>
                        We help your brand create and tell its own stories that help
                        <b>inform</b> the brand’s audience of its <b>latest </b> updates.
                      </p>
                    </div>
                  </div>
                  <div class="item_div">
                    <label>4.4</label>
                    <h4>Social media</h4>
                    <div class="img_content">
                      <figure style="background-image: url('assets/services/social_mediass.jpg')">
                        <a routerLink="/service-details"><i class="fa-solid fa-arrow-right"></i></a>
                      </figure>
                      <p>
                        We help you <b>build</b> connections with your audience through
                        strategically prepared social media plans and posts.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
