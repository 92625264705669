<section class="service_sec creative" id="creative" data-scroll-section>
  <div class="is_mobile_image">
    <figure
      class="section_img lazyload"
      style="background-image: url('assets/about/creative.jpg')"
    ></figure>
  </div>
  <div
    class="img_wraper"
    data-scroll
    data-scroll-repeat
    data-scroll-sticky
    data-scroll-target="#creative"
  >
    <span data-scroll data-scroll-speed="1.5">
      <figure
        class="section_img lazyloaded"
        style="background-image: url('assets/about/creative.jpg')"
      ></figure>
    </span>
  </div>
  <div class="row">
    <div class="content_div" data-scroll data-scroll-speed="1">
      <div class="sec_head">
        <span class="for_mob">Services</span>
        <h2>Stories We Unfold</h2>
      </div>
      <p>Deep beneath, we are all storytellers waiting to tell your story to the world.</p>
      <a routerLink="services" class="common-arrow">
        Watch Showreel
        <span><i class="fa-solid fa-arrow-right"></i></span>
      </a>
    </div>
    <div class="for_mob_scrll">
      <div class="col_item">
        <div class="item_div">
          <h3>Creative</h3>
          <div class="img_content">
            <figure class="lazyloaded" style="background-image: url('assets/about/running.jpg')">
              <a routerLink="services" (click)="selectTab('creative')"
                ><i class="fa-solid fa-arrow-right"></i
              ></a>
            </figure>
            <p>
              A picture is worth a thousand words. And the pictures our designers can capture can
              portray the totality of your brand’s image.
            </p>
          </div>
        </div>
        <div class="item_div">
          <h3>Website</h3>
          <div class="img_content">
            <figure
              class="lazyloaded"
              style="background-image: url('assets/about/development.jpg')"
            >
              <a routerLink="services" (click)="selectTab('web')"
                ><i class="fa-solid fa-arrow-right"></i
              ></a>
            </figure>
            <p>
              Where can one visit to become personal with your brand? Yes, your brand home. Our
              Website developers along with our designers and writers work together in architecting
              the perfect digital home where your brand can be free to express itself. This is where
              anyone and everyone can visit to get acquainted with your brand.
            </p>
          </div>
        </div>
        <div class="item_div">
          <h3>Performance</h3>
          <div class="img_content">
            <figure
              class="lazyloaded"
              style="background-image: url('assets/about/performance.jpg')"
            >
              <a routerLink="services" (click)="selectTab('performance')"
                ><i class="fa-solid fa-arrow-right"></i
              ></a>
            </figure>
            <p>
              Reach where no man or company has reached before with specialised strategies and
              optimised data to target and reach your treasured audience wherever they are.
            </p>
          </div>
        </div>
        <div class="item_div">
          <h3>Content</h3>
          <div class="img_content">
            <figure class="lazyloaded" style="background-image: url('assets/about/content.jpg')">
              <a routerLink="services" (click)="selectTab('content')"
                ><i class="fa-solid fa-arrow-right"></i
              ></a>
            </figure>
            <p>
              Words have power of their own and when placed carefully together they come out with
              meaning. Our writers craft words carefully together to give your brand the perfect
              identity and message it needs to deliver to your audience.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
