<section class="service_sec award" id="award" data-scroll-section>
  <div
    class="img_wraper"
    data-scroll
    data-scroll-repeat
    data-scroll-sticky
    data-scroll-target="#award"
  >
    <span data-scroll data-scroll-speed="1.5">
      <figure
        class="section_img lazyloaded"
        style="background-image: url('assets/about-page/award_sec.jpg')"
      ></figure>
    </span>
  </div>
  <div class="row">
    <div class="content_div award_div" data-scroll data-scroll-speed="1">
      <div class="sec_head">
        <h2>Awards</h2>
      </div>
      <p>
        Recognition has given us the opportunity to do better. Here are some laurels we won for our
        projects.
      </p>
      <p class="lg:mt-[-30px]">Take a peek and get inspired!</p>
    </div>
    <div class="portfolio-list">
      <div class="portfolio-item">
        <div class="project-title flex flex-wrap items-center justify-between">
          <h2>Fanplay</h2>
        </div>
        <figure class="relative overflow-hidden block">
          <img class="image_01" src="/assets/about-page/awards.jpg" />
          <img class="image_02" src="/assets/about-page/awards.jpg" />
          <a class="go-to-project flex items-center justify-center absolute text-center"
            ><span><i class="fa-solid fa-arrow-right flex"></i></span
          ></a>
        </figure>
      </div>
    </div>
  </div>
</section>
