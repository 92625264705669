<section class="service_sec" id="portfolio" data-scroll-section>
  <div
    class="portfolio-img-wrap img_wraper is-inview"
    data-scroll
    data-scroll-repeat
    data-scroll-sticky
    data-scroll-target="#portfolio"
  >
    <span data-scroll data-scroll-speed="1.5" class="is-inview">
      <figure
        class="section_img section-img lazyloaded"
        style="background-image: url('assets/image/portfolio.jpg')"
      >
        <picture class="hidden">
          <source />
          <img />
        </picture>
      </figure>
    </span>
  </div>

  <div class="row">
    <div class="content_div is-inview portfolio-content" data-scroll data-scroll-speed="1">
      <div class="sec_head">
        <h2>Portfolio</h2>
      </div>
      <p>
        We are what we do and what we do-<br />We Advertise. <br />“Dont tell, show” has been our
        motto from Day 1 which is why we’d rather let our work speak for us. Come, have a look!
      </p>
      <div class="leading-4">
        <a href="#" class="common-arrow">
          View all works
          <span><i class="fa-solid fa-arrow-right"></i></span>
        </a>
      </div>
    </div>
    <div class="portfolio-list">
      @if(projectList.length>0){ @for(projects of projectList.slice(0,3);track projects){
      <div class="portfolio-item">
        <div class="project-title flex flex-wrap items-center justify-between">
          <h2>{{ projects.title }}</h2>
        </div>
        <figure class="relative overflow-hidden block">
          <img class="image_01" src="/assets/image/Pasted image.png" />
          <img class="image_02" src="/assets/image/Pasted image (2).png" />
          <a class="go-to-project flex items-center justify-center absolute text-center"
            ><span><i class="fa-solid fa-arrow-right flex"></i></span
          ></a>
        </figure>
        <div class="project-desc">
          <p>{{ projects.projectShortDesc }}</p>
        </div>
      </div>
      }} @if(projectList.length === 0){
      <div class="portfolio-item">
        <div class="project-title flex flex-wrap items-center justify-between">
          <h2>Parko</h2>
        </div>
        <figure class="relative overflow-hidden block">
          <img class="image_01" src="/assets/image/Pasted image.png" />
          <img class="image_02" src="/assets/image/Pasted image (2).png" />
          <a class="go-to-project flex items-center justify-center absolute text-center"
            ><span><i class="fa-solid fa-arrow-right flex"></i></span
          ></a>
        </figure>
        <div class="project-desc">
          <p>RentTech for Parko LLC</p>
        </div>
      </div>
      <div class="portfolio-item">
        <div class="project-title flex flex-wrap items-center justify-between">
          <h2>Heatscape</h2>
        </div>
        <figure class="relative overflow-hidden block">
          <img class="image_01" src="/assets/image/Pasted image.png" />
          <img class="image_02" src="/assets/image/Pasted image (2).png" />
          <a class="go-to-project flex items-center justify-center absolute text-center"
            ><span><i class="fa-solid fa-arrow-right flex"></i></span
          ></a>
        </figure>
        <div class="project-desc">
          <p>Fintech for Heatscape Inc</p>
        </div>
      </div>
      <div class="portfolio-item">
        <div class="project-title flex flex-wrap items-center justify-between">
          <h2>Goi Car</h2>
        </div>
        <figure class="relative overflow-hidden block">
          <img class="image_01" src="/assets/image/Pasted image.png" />
          <img class="image_02" src="/assets/image/Pasted image (2).png" />
          <a class="go-to-project flex items-center justify-center absolute text-center"
            ><span><i class="fa-solid fa-arrow-right flex"></i></span
          ></a>
        </figure>
        <div class="project-desc">
          <p>RentTech for Goicar Car Rental</p>
        </div>
      </div>
      }
    </div>
    <div class="scroll_button is-inview fixed flex items-center mt-[30px]">
      <a class="click_page flex items-center" href="#"
        ><i class="fa-solid fa-arrow-right"></i>
        <p class="pointer-events-none">View All</p>
      </a>
    </div>
    <div class="leading-4 view-btn">
      <a href="#" class="common-arrow">
        View all
        <span><i class="fa-solid fa-arrow-right"></i></span>
      </a>
    </div>
  </div>
</section>
