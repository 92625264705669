import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { DemoComponent } from './components/demo/demo.component';
import { HomeComponent } from './components/home-page/home/home.component';
import { PortfolioService } from './core/services/portfolio.service';
import { HttpClientModule } from '@angular/common/http';
import { FooterComponent } from './components/footer/footer.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    HttpClientModule,
    RouterOutlet,
    HeaderComponent,
    SidebarComponent,
    DemoComponent,
    HomeComponent,
    FooterComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [PortfolioService],
})
export class AppComponent {
  title = 'hardikgodhani.com';
  dotPosition = { x: 0, y: 0 };
  dotScale = 1;
  isScaling = false;

  onMouseMove(event: MouseEvent): void {
    const targetX = event.clientX - 10;
    const targetY = event.clientY - 10;
    this.dotPosition.x = targetX;
    this.dotPosition.y = targetY;
    this.updateScale(event.target);
  }
  updateScale(target: EventTarget | null): void {
    this.isScaling = false;

    if (target instanceof HTMLElement) {
      if (
        target.tagName.toLowerCase() === 'a' ||
        target.tagName.toLowerCase() === 'button' ||
        target.className == 'rise'
      ) {
        console.log(target.className);
        this.isScaling = true;
        this.dotScale = 2.5;
      } else {
        this.isScaling = false;
        this.dotScale = 1;
      }
    }
  }
}
