import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class RippleService {
  constructor() {}
  createRipple(event: MouseEvent, element: HTMLElement) {
    const ripple = element.querySelector('span') as HTMLSpanElement;

    // Calculate the position of the mouse relative to the element
    const rect = element.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    // Position the ripple
    ripple.style.left = `${x}px`;
    ripple.style.top = `${y}px`;
  }
}
