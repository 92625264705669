import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import LocomotiveScroll from 'locomotive-scroll';

@Component({
  selector: 'app-common-details-page',
  standalone: true,
  imports: [],
  templateUrl: './common-details-page.component.html',
  styleUrl: './common-details-page.component.scss',
})
export class CommonDetailsPageComponent {
  isBrowser: boolean;
  private scroll: LocomotiveScroll | undefined;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngAfterViewInit() {
    this.initializeScroll();
  }

  private initializeScroll() {
    if (this.isBrowser) {
      const element = document.querySelector('[data-scroll-container]') as HTMLElement;
      const direction = window.innerWidth <= 1024 ? 'vertical' : 'horizontal';

      this.scroll = new LocomotiveScroll({
        el: element,
        smooth: true,
        direction: direction,
        smartphone: { smooth: true },
      });

      this.scroll.update();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    if (this.isBrowser) {
      if (this.scroll) {
        this.scroll.destroy();
      }
      this.initializeScroll();
    }
  }
}
