import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PortfolioService {
  projectList$: BehaviorSubject<any> = new BehaviorSubject({});
  projectListLoc: any;

  constructor(private http: HttpClient) {
    this.getProjectList().subscribe((data: any) => {
      if (data.data && data.data.data) {
        this.projectList$.next(data);
        this.projectListLoc = data;
      }
    });
  }

  getProjectList() {
    if (this.projectListLoc && this.projectListLoc.data.data) {
      return this.projectList$;
    } else {
      let payload = {
        query: {
          isDeleted: false,
        },
        options: {
          page: 1,
          paginate: 2000,
        },
        isCountOnly: false,
      };
      return this.http.post(environment.mainUrl + `admin/portfolio/list`, payload);
    }
  }

  getProjectById(id: string) {
    return this.http.get(environment.mainUrl + `admin/portfolio/${id}`);
  }
}
