import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component, ElementRef } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ToastService } from '../../../core/services/toast.service';
import * as $ from 'lodash';
import { NavigationEnd, Router, RouterModule } from '@angular/router';

interface Service {
  name: string;
  selected: boolean;
}
@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, HttpClientModule, RouterModule],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss',
})
export class ContactComponent {
  is1Active = true;
  is2Active = false;
  talkForm: any;
  contactForm: any;
  showClass: boolean = false;

  defaultService = [
    { name: 'Website', selected: false },
    { name: 'App design', selected: false },
    { name: 'Branding', selected: false },
    { name: 'Logo Design', selected: false },
    { name: 'Html/css coding', selected: false },
    { name: 'Graphic design', selected: false },
    { name: 'Illustrations', selected: false },
    { name: 'Website Design', selected: false },
    { name: 'Ui/Ux Design', selected: false },
  ];

  services: Service[] = $.cloneDeep(this.defaultService);
  constructor(
    private fb: FormBuilder,
    private elementRef: ElementRef,
    private toastService: ToastService,
    private http: HttpClient,
    private router: Router
  ) {
    this.talkForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      phone: ['', [Validators.required, Validators.minLength(10), Validators.pattern('^[0-9]*$')]],
      email: ['', [Validators.required, Validators.email]],
      desc: ['', [Validators.required, Validators.minLength(3)]],
      services: [[], [Validators.required]],
      type: 'contactform',
    });
    // this.contactForm = this.fb.group({
    //   name: ['', [Validators.required, Validators.minLength(3)]],
    //   phone: ['', [Validators.required, Validators.minLength(10), Validators.pattern('^[0-9]*$')]],
    //   email: ['', [Validators.required, Validators.email]],
    //   desc: ['', [Validators.required, Validators.minLength(3)]],
    //   type: 'talkform',
    // });
    router.events.subscribe((value) => {
      if (value instanceof NavigationEnd) {
        if (value.url == '/contact') {
          this.showClass = true;
        } else {
          this.showClass = false;
        }
      }
    });
  }
  selectService(service: Service) {
    service.selected = !service.selected;
  }

  ngOnInit(): void {}

  onSubmit(): void {
    this.getSelectedService();
    if (this.talkForm.valid) {
      const formData = this.talkForm.value;
      this.http.post('http://nexdevapi.nexotips.com/admin/clientQuery/create', formData).subscribe(
        (response) => {
          this.toastService.showToast(this.elementRef, 'Form submitted successfully!', 'success');
          this.services = $.cloneDeep(this.defaultService);
          this.talkForm.reset();
        },
        (error) => {
          this.toastService.showToast(this.elementRef, 'Error submitting form.', 'error');
        }
      );
    } else {
      Object.values(this.talkForm.controls).forEach((control: any) => {
        control.markAsTouched();
      });
    }
  }

  getSelectedService() {
    let res: any = [];
    this.services.forEach((service) => {
      if (service.selected) {
        res.push(service.name);
      }
    });
    this.talkForm.get('services')?.setValue(res.toString());
  }

  talkBtn() {
    this.is1Active = true;
    this.is2Active = false;
  }

  contactBtn() {
    this.is2Active = true;
    this.is1Active = false;
  }
}
