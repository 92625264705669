import { Component } from '@angular/core';

@Component({
  selector: 'app-awards-section',
  standalone: true,
  imports: [],
  templateUrl: './awards-section.component.html',
  styleUrl: './awards-section.component.scss'
})
export class AwardsSectionComponent {

}
