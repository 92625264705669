@if(showSidebar){
<div
  class="sidebar-container top-0 left-0 fixed h-screen flex flex-wrap items-center justify-between flex-col"
>
  <div class="logo">
    <a href="#">
      <img class="w-full" src="../../../assets/icon/logo.svg" alt="logo" />
    </a>
  </div>
  <div class="sidebar-menu fixed block"></div>
</div>
}
