import { Component, Inject } from '@angular/core';
import LocomotiveScroll from 'locomotive-scroll';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-demo',
  standalone: true,
  imports: [],
  templateUrl: './demo.component.html',
  styleUrl: './demo.component.scss',
})
export class DemoComponent {
  isBrowser: any;
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit() {
    this.isBrowser = isPlatformBrowser(this.platformId);

    if (this.isBrowser) {
      const element = document.querySelector('[data-scroll-container]') as HTMLElement;
      const scroll = new LocomotiveScroll({
        el: element,
        smooth: true,
        direction: 'horizontal',
        smartphone: { smooth: true },
      });
    }
  }
}
