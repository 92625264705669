import { Component, NgZone } from '@angular/core';
import { PortfolioService } from '../../../core/services/portfolio.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-portfolio',
  standalone: true,
  imports: [],
  templateUrl: './portfolio.component.html',
  styleUrl: './portfolio.component.scss',
})
export class PortfolioComponent {
  projectList: any = [];

  constructor(private portfolioService: PortfolioService, private ngZone: NgZone) {}

  ngOnInit() {
    this.ngZone.runOutsideAngular(() => {
      this.portfolioService.getProjectList().subscribe((data: any) => {
        if (data.data && data.data.data) {
          this.projectList = data.data.data;
        }
      });
    });
  }
}
