<!-- menu start -->
<div [ngClass]="{ open: !isShown }">
  <div class="menu">
    <div class="menu_wrapper">
      <div class="menu_click" (click)="closeMenu()">
        <i></i>
        <i></i>
      </div>
      <span class="menu_lable">Menu</span>
      <figure class="logo">
        <a routerLink="/" (click)="closeMenu()">
          <img src="/assets/icon/main_logo.svg" alt="main_logo" />
        </a>
      </figure>
      <ul class="social_link">
        <li>
          <a target="_blank" href="https://twitter.com/nexotips">Tw</a>
        </li>
        <li><a target="_blank" href="https://www.facebook.com/nexotipstech">Fb</a></li>
        <li>
          <a target="_blank" href="https://in.linkedin.com/company/nexotips">In</a>
        </li>
        <li><a target="_blank" href="https://blog.nexotips.com">Bl</a></li>
        <li><a target="_blank" href="https://www.instagram.com/nexotips/">Ig</a></li>
      </ul>
      <div class="text">
        <p>© 2021 Audento Digital. All rights reserved.</p>
      </div>
      <div class="menu_wraper">
        <ul class="nav_main_menu">
          <li (click)="closeMenu()">
            <a routerLink="/"><span>01</span> Home</a>
          </li>
          <li (click)="closeMenu()">
            <a routerLink="about"><span>02</span> About</a>
          </li>
          <li (click)="closeMenu()">
            <a routerLink="services" (click)="selectTab('creative')"><span>03</span> Services</a>
            <ul routerLink="services">
              <li><a (click)="selectTab('creative')">Creative</a></li>
              <li><a (click)="selectTab('web')">Web</a></li>
              <li><a (click)="selectTab('performance')">Performance</a></li>
              <li><a (click)="selectTab('content')">Content</a></li>
            </ul>
          </li>
          <li (click)="closeMenu()">
            <a routerLink="contact" class="get_in_touch"><span>04</span> Get in touch</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- menu end -->
<!-- header start -->
<header>
  <div
    class="top-header w-screen fixed top-0 right-0 flex justify-between flex-wrap items-center"
    [ngClass]="{ dispaly: isActive }"
  >
    <div class="top-header-li">
      <ul [ngClass]="{ left: isActive }">
        <li>
          <a target="_blank" href="https://twitter.com/nexotips">
            <p>Tw</p>
            <span><i class="fa-brands fa-twitter"></i></span>
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.facebook.com/nexotipstech">
            <p>Fb</p>
            <span><i class="fa-brands fa-facebook-f"></i> </span>
          </a>
        </li>
        <li>
          <a target="_blank" href="https://in.linkedin.com/company/nexotips">
            <p>In</p>
            <span><i class="fa-brands fa-linkedin-in"></i></span>
          </a>
        </li>
        <li>
          <a target="_blank" href="https://blog.nexotips.com">
            <p>Bl</p>
            <span><i class="fa-solid fa-blog"></i></span>
          </a>
        </li>
        <li>
          <a target="_blank" href="https://www.instagram.com/nexotips/">
            <p>Ig</p>
            <span><i class="fa-brands fa-instagram"></i></span>
          </a>
        </li>
      </ul>
    </div>

    <div class="top-header-menu flex flex-wrap items-center">
      <div class="logo_for_mob">
        <a routerLink="/">
          <img src="/assets/icon/main_logo.svg" alt="main_logo" />
        </a>
      </div>
      <div
        (mouseover)="onMouseOver($event)"
        class="header-button relative text-white text-center overflow-hidden inline-block text-base font-bold cursor-pointer z-10"
      >
        <span class="absolute block -z-10 rounded-full w-0 h-0"></span>Estimate Project
      </div>
      <div class="cursor-pointer" (click)="closeMenu()">
        <span class="menu-icon">
          <img src="/assets/icon/menu.svg" alt="menu-icon" class="inline-block" />
        </span>
      </div>
    </div>
  </div>
</header>
<!-- header end -->
