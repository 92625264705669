<div data-scroll-container>
  <div class="content relative h-screen">
    <div class="progress-bar" [style.height.%]="scrollProgress"></div>
    <div class="home block lg:flex items-center" id="about_page">
      <section class="service_sec about_sec" id="about" data-scroll-section>
        <div class="row about_disc">
          <div class="content_div about_content" data-scroll data-scroll-speed="1">
            <span class="for_mob">About Us</span>
            <div class="sec_head">
              <h2>What we do</h2>
            </div>
            <p>
              <b>We Break</b> away the noise and build your Brand from the core. We just love
              breaking stuff down to the quintessential core to bring forward the core messaging
              needed for your brand.
            </p>
            <p>
              <b>We Build</b> simple, strong and innovative bridges. Building bridges is something
              else we love architecting. We find new, innovative &amp; powerful ways to bridge our
              clients to their customers.
            </p>
            <p>
              <b>We Bridge</b> an everlasting communication between our client and their customer.
              We don't stop there, we make sure the bridges are complete and tested thoroughly for
              timeless communication between our client and customer so that they may find an
              everlasting friendship along the way.
            </p>
          </div>
        </div>
        <div class="abt_image" data-scroll data-scroll-speed=".8">
          <div class="">
            <figure
              class="lazyloaded"
              style="background-image: url('assets/about-page/team_work.jpg')"
            ></figure>
          </div>
        </div>
      </section>
      <app-awards-section></app-awards-section>
      <app-affiliation-section></app-affiliation-section>
      <app-join-us></app-join-us>
    </div>
  </div>
</div>
