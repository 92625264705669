import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import LocomotiveScroll from 'locomotive-scroll';
import { AwardsSectionComponent } from './awards-section/awards-section.component';
import { AffiliationSectionComponent } from './affiliation-section/affiliation-section.component';
import { JoinUsComponent } from './join-us/join-us.component';

@Component({
  selector: 'app-about-page',
  standalone: true,
  imports: [RouterModule, AwardsSectionComponent, AffiliationSectionComponent, JoinUsComponent],
  templateUrl: './about-page.component.html',
  styleUrl: './about-page.component.scss',
})
export class AboutPageComponent implements AfterViewInit {
  isBrowser: boolean;
  private scroll: LocomotiveScroll | undefined;
  scrollProgress = 0;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngAfterViewInit() {
    this.initializeScroll();
  }

  private initializeScroll() {
    if (this.isBrowser) {
      const element = document.querySelector('[data-scroll-container]') as HTMLElement;
      const direction = window.innerWidth <= 1024 ? 'vertical' : 'horizontal';

      this.scroll = new LocomotiveScroll({
        el: element,
        smooth: true,
        direction: direction,
        smartphone: { smooth: true },
      });

      this.scroll.update();
      this.scroll.on('scroll', (args: any) => {
        const { scroll, limit } = args;
        this.scrollProgress = (scroll.x / limit.x) * 100;
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    if (this.isBrowser) {
      if (this.scroll) {
        this.scroll.destroy();
      }
      this.initializeScroll();
    }
  }
}
