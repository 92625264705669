import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { RouterLink } from '@angular/router';
import LocomotiveScroll from 'locomotive-scroll';
import { RouteService } from '../../../core/services/route.service';

@Component({
  selector: 'app-services',
  standalone: true,
  imports: [RouterLink, CommonModule],
  templateUrl: './services.component.html',
  styleUrl: './services.component.scss',
})
export class ServicesComponent implements AfterViewInit {
  selectedTab: string = 'creative';
  isBrowser: boolean;
  private scroll: LocomotiveScroll | undefined;
  isWork: boolean = false;
  scrollProgress = 0;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private routeService: RouteService) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit() {
    this.routeService.selectedTab$.subscribe((tab: string) => {
      this.selectedTab = tab;
    });
  }

  ngAfterViewInit() {
    this.initializeScroll();
  }

  private initializeScroll() {
    if (this.isBrowser) {
      const element = document.querySelector('[data-scroll-container]') as HTMLElement;
      const direction = window.innerWidth <= 1024 ? 'vertical' : 'horizontal';

      this.scroll = new LocomotiveScroll({
        el: element,
        smooth: true,
        direction: direction,
        smartphone: { smooth: true },
      });

      this.scroll.update();
      this.scroll.on('scroll', (args: any) => {
        const { scroll, limit } = args;
        this.scrollProgress = (scroll.x / limit.x) * 100;
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    if (this.isBrowser) {
      if (this.scroll) {
        this.scroll.destroy();
      }
      this.initializeScroll();
    }
  }
  selectTab(tab: string) {
    this.routeService.setSelectedTab(tab);
  }
}
