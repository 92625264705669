import { ElementRef, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  showToast(elementRef: ElementRef, message: string, type: 'success' | 'error'): void {
    const toastElement = elementRef.nativeElement.querySelector('.toast');
    if (toastElement) {
      const toastMessageElement = toastElement.querySelector('.toast-message');
      const toastCloseButton = toastElement.querySelector('.toast-close');

      toastMessageElement.textContent = message;
      toastElement.className = `toast ${type}`;
      toastElement.classList.add('show');

      if (toastCloseButton) {
        toastCloseButton.addEventListener('click', () => {
          toastElement.classList.remove('show');
        });
      }

      setTimeout(() => {
        toastElement.classList.remove('show');
      }, 1000);
    }
  }
}
