<section class="service_sec join-us" id="join-us" data-scroll-section>
  <div
    class="img_wraper"
    data-scroll
    data-scroll-repeat
    data-scroll-sticky
    data-scroll-target="#join-us"
  >
    <span data-scroll data-scroll-speed="1.5">
      <figure
        class="section_img lazyloaded"
        style="background-image: url('assets/about-page/join_us.jpg')"
      ></figure>
    </span>
  </div>
  <div class="join_us_div">
    <h2>Care To Join Us ?</h2>
    <p>
      Just fill this simple form in and we will contact you promptly to discuss your project. Hate
      forms? Drop us a line at
      <a>connect &#64; audentodigital.com</a> .
    </p>
  </div>
</section>
