// typewriter.service.ts
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TypewriterService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  typeWriter(text: string, i: number, element: HTMLElement, fnCallback: () => void): void {
    if (i < text.length) {
      this.renderer.setProperty(
        element,
        'innerHTML',
        text.substring(0, i + 1) + '<span aria-hidden="true"></span>'
      );
      setTimeout(() => {
        this.typeWriter(text, i + 1, element, fnCallback);
      }, 100);
    } else if (typeof fnCallback === 'function') {
      setTimeout(fnCallback, 700);
    }
  }

  reverseTypeWriter(text: string, i: number, element: HTMLElement, fnCallback: () => void): void {
    if (i >= 0) {
      this.renderer.setProperty(
        element,
        'innerHTML',
        text.substring(0, i) + '<span aria-hidden="true"></span>'
      );
      setTimeout(() => {
        this.reverseTypeWriter(text, i - 1, element, fnCallback);
      }, 100);
    } else if (typeof fnCallback === 'function') {
      setTimeout(fnCallback, 700);
    }
  }

  startTextAnimation(i: number, dataText: string[], element: HTMLElement): void {
    if (typeof dataText[i] === 'undefined') {
      setTimeout(() => {
        this.startTextAnimation(0, dataText, element);
      }, 700);
      return;
    }

    if (i < dataText.length) {
      this.typeWriter(dataText[i], 0, element, () => {
        this.reverseTypeWriter(dataText[i], dataText[i].length, element, () => {
          this.startTextAnimation(i + 1, dataText, element);
        });
      });
    }
  }
}
